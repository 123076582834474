import { onNextRepaint } from '~/shared/utils/animation';
import { InternalTracking } from '~/shared/modules/internal-tracking';

export const removeReminderCallout = () => {
  const messageCallout = document.querySelector('[data-reminder-callout]');

  if (!messageCallout) return;

  messageCallout.classList.add('reminder-callout--hidden');
  setTimeout(() => {
    messageCallout.innerHTML = '';
  }, 250);
};

export const hasContactInfo = (value) => {
  const regexes = Dribbble?.JsConfig?.engagements?.contact_info_regexes?.map((regex) => new RegExp(regex, 'i')) || [];
  if (!regexes.length) return;

  // eslint-disable-next-line consistent-return
  return regexes.some((regex) => regex.test(value));
};

export const toggleShakeAnimation = (element, shouldShake) => {
  // Remove shake class if present in order to restart animation
  element.classList.remove('animation-shake');

  // Add animation on next repaint to ensure it replays
  if (shouldShake) {
    onNextRepaint(() => {
      element.classList.add('animation-shake');
    });
  }
};

let hasTracked;

const trackInfoWarningShown = (warningElement) => {
  const payload = {
    location: warningElement.getAttribute('data-contact-info-warning') || '',
    sender_id: warningElement.getAttribute('data-sender-id') || '',
    thread_id: warningElement.getAttribute('data-thread-id') || '',
  };
  InternalTracking.trackContactInfoWarningShown(payload);
};

export const toggleContactInfoWarning = (warningElement, isEnabled) => {
  if (!warningElement) return;
  warningElement.classList.toggle('contact-info-warning--visible', isEnabled);

  if (isEnabled && !hasTracked) {
    trackInfoWarningShown(warningElement);
    hasTracked = true;
  } else if (!isEnabled) {
    hasTracked = false; // reset tracking when warning is removed
  }
};
